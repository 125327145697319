var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "div",
        {
          staticClass: "mid fixed w-90 w-80-l h-100",
          staticStyle: { "border-right": "0", "background-color": "#f5f6fa" },
        },
        [
          _c("div", { staticClass: "w-70 center pv5" }, [
            _c("div", {}, [
              _c("div", { staticClass: "fl w-25 tc pa2" }, [
                _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                  _c("h2", { staticClass: "mb2" }, [_vm._v("-")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "truncate" }, [_vm._v("Sales")]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "fl w-25 tc pa2" }, [
                _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                  _c("h2", { staticClass: "mb2" }, [_vm._v("-")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "truncate" }, [_vm._v("Purchase")]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "fl w-25 tc pa2" }, [
                _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                  _c("h2", { staticClass: "mb2" }, [_vm._v("-")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("Expense")]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "fl w-25 tc pa2" }, [
                _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                  _c("h2", { staticClass: "mb2" }, [_vm._v("-")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "truncate" }, [_vm._v("Balance")]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cb" }),
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "mt4 w-100" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Date")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("ID")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Type")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Entity name")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Description")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Amount")]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }