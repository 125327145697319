<template>
  <div>
    <div class="modal" :class="productModal ? '' : 'dn'">
      <div class="modal-body w-50 center mt5 pa4">
        <h3 class="asc ma0 pb2">Add new product</h3>
        <form method="post" @submit.prevent="createProduct">
          <div>
            <div class="mv1 flex justify-between">
              <div class="w-50 mr1">
                <label class="db fade pv1">SKU</label>
                <input type="text" v-model="new_product.sku" class="w-100" />
              </div>
              <div class="w-50 ml1">
                <label class="db fade pv1">Name</label>
                <input type="text" v-model="new_product.name" class="w-100" required />
              </div>
            </div>
            <div class="mv1">
              <label class="db fade pv1">Description</label>
              <textarea class="w-100" v-model="new_product.description"></textarea>
            </div>
            <div class="mv1 flex justify-between">
              <div class="w-50 mr1">
                <label class="db fade pv1">Size</label>
                <input type="text" v-model="new_product.size" class="w-100" />
              </div>
              <div class="w-50 ml1">
                <label class="db fade pv1">Color</label>
                <input type="text" v-model="new_product.color" class="w-100" />
              </div>
            </div>
          </div>
          <div class="mt4 flex justify-between">
            <button class="w-40" type="submit" :disabled="addingProduct">
              {{ addingProduct ? "Creating product..." : "Create product" }}
            </button>
            <button class="button-grey" type="button" @click="productModal = false">Close</button>
          </div>
        </form>
      </div>
    </div>
    <div class="mid fixed w-90 w-80-l h-100" style="border-right:0;background-color:#f5f6fa">
      <div class="w-50 center pv5">
        <h3 class="asc ma0 pv1">Create new transaction</h3>
        <div class="info pb3" v-if="info">{{ info }}</div>
        <div class="pv1 flex justify-between">
          <div class="w-50 pr1">
            <label class="db fade pv1">Type</label>
            <select v-model="type" class="w-100">
              <option value="">-Select-</option>
              <option value="sales">Sales</option>
              <option value="purchases">Purchases (COGS)</option>
              <option value="expenses">Expenses</option>
              <option value="inflows">Others - Inflows</option>
              <option value="outflows">Others - Outflows</option>
            </select>
          </div>
          <div class="w-50 pl1" v-if="type">
            <div v-if="type == 'expenses'">
              <label class="db fade pv1">Expenses</label>
              <select class="w-100" v-model="subtype">
                <option value="">-Category-</option>
                <option value="transport">Transportation</option>
                <option value="commission">Commission & Fees</option>
                <option value="bank">Bank Charges</option>
                <option value="ads">Advertisement</option>
                <option value="packaging">Packaging</option>
                <option value="discount">Discount Given</option>
                <option value="salary">Salary</option>
                <option value="fees">Fees</option>
                <option value="rent">Rent</option>
                <option value="assets">Assets Acquisition</option>
                <option value="utility">Utility</option>
                <option value="interest_expense">Interest Expense</option>
                <option value="other">Add more</option>
              </select>
            </div>
            <div v-else-if="type == 'inflows'">
              <label class="db fade pv1">Others - Inflow</label>
              <select class="w-100" v-model="subtype">
                <option value="">-Category-</option>
                <option value="capital">Capital</option>
                <option value="borrowings">Borrowings</option>
                <option value="service">Service Charge</option>
                <option value="advance_payment">Customer Advance/Deposit</option>
                <option value="shipping">Shipping Fee</option>
                <option value="interest">Interest Earning</option>
                <option value="vat">VAT Received</option>
                <option value="purchase_return">Purchase Return</option>
                <option value="stock_introduced">Stock Introduced</option>
                <option value="discount">Discount Received</option>
                <option value="debt_repayment">Debt Repayment</option>
                <option value="gift_received">Gift Received</option>
                <option value="others">Other Fees</option>
              </select>
            </div>
            <div v-else-if="type == 'outflows'">
              <label class="db fade pv1">Others - outflow</label>
              <select class="w-100" v-model="subtype">
                <option value="">-Category-</option>
                <option value="asset">Asset Purchase</option>
                <option value="loan">Loan Repayment</option>
                <option value="cc">Credit Card Payment</option>
                <option value="drawings">Drawings</option>
                <option value="sales_return">Sales Return</option>
                <option value="damaged_stock">Damaged Stock</option>
                <option value="missing_stock">Missing Stock</option>
                <option value="promotion_stock">Stock used in promotion</option>
                <option value="loans_given">Loans Given out</option>
                <option value="bad_debt">Bad Debt</option>
                <option value="vat_remittance">VAT Remittance</option>
                <option value="others">Others</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="type == 'expenses'">
          <div>
            <label class="db fade pv1"><input type="checkbox" v-model="prepayment" /> This is a prepayment.</label>
          </div>
          <div class="flex justify-between items-center w-100" v-if="prepayment">
            <div class="w-50 mr1">
              <label class="db fade pv1">Number of months</label>
              <input type="number" class="w-100" v-model="prepayment_months" />
            </div>
            <div class="w-50 mr1 ml1">
              <label class="db fade pv1">End date</label>
              <input type="date" class="w-100" readonly />
            </div>
          </div>
        </div>
        <div class="pv1" v-if="subtype == 'purchase_return' || subtype == 'sales_return'">
          <label class="db fade pv1">Reference transaction ID</label>
          <input type="text" class="w-100" v-model="parent_ref" />
        </div>
        <div
          class="pv1"
          v-if="
            type == 'sales' ||
              type == 'purchases' ||
              subtype == 'stock_introduced' ||
              subtype == 'purchase_return' ||
              subtype == 'sales_return' ||
              /stock/.test(subtype)
          "
        >
          <div>
            <div class="flex items-center">
              <label class="db fade b pv1 mr2">Product(s)</label>
              <small v-if="type == 'purchases'"><a href="#" @click.prevent="productModal = true">Create new product</a></small>
            </div>
            <div class="flex justify-between w-100">
              <div
                :class="
                  subtype == 'purchase_return' || subtype == 'sales_return' || /stock/.test(subtype) ? 'w-two-thirds' : 'w-third'
                "
                class="mr1"
              >
                <label class="db fade pv1">Product</label>
              </div>
              <div v-if="type == 'sales' || type == 'purchases'" class="w-third mr1 ml1">
                <label class="db fade pv1"
                  ><template v-if="type == 'purchases'">Purchase</template><template v-else>Sales</template> price</label
                >
              </div>
              <div class="w-third ml1">
                <label class="db fade pv1">Quantity</label>
              </div>
            </div>
            <div class="flex justify-between w-100 pv1" v-for="(product, index) of products" :key="index">
              <div
                :class="
                  subtype == 'purchase_return' || subtype == 'sales_return' || /stock/.test(subtype) ? 'w-two-thirds' : 'w-third'
                "
                class="mr1"
              >
                <v-select @search="fetchProduct" v-model="product.product" style="min-width:150px" :options="tmpOptions">
                  <template #no-options="{ search, searching, loading }" class="fade pa1">
                    Type product name...
                  </template>
                </v-select>
              </div>
              <div v-if="type == 'sales' || type == 'purchases'" class="w-third ml1 mr1">
                <input type="text" class="w-100" v-model="product.price" />
              </div>
              <div class="w-third ml1">
                <input type="number" class="w-100" v-model="product.quantity" />
              </div>
            </div>
          </div>
          <div>
            <div class="pv1" v-for="o of additionalOptions" :key="o.key">
              <label class="db fade pv1">{{ o.label }}</label>
              <div class="flex justify-between items-center w-100">
                <div class="w-50 mr1">
                  <input type="text" class="w-100" v-model="o.value" />
                </div>
                <div class="w-50 ml1">
                  Fixed <input type="radio" v-model="o.fixed" value="Y" class="mr2" /> Percentage
                  <input type="radio" v-model="o.fixed" value="N" />
                </div>
              </div>
            </div>
          </div>
          <small v-if="type == 'sales'"
            ><a href="#" @click.prevent="moreProducts">Add more products</a> |
            <a href="#" @click.prevent="addOtherOptions('shipping')">Add Shipping</a> |
            <a href="#" @click.prevent="addOtherOptions('vat')">Add VAT</a> |
            <a href="#" @click.prevent="addOtherOptions('commission')">Add Commission</a> |
            <a href="#" @click.prevent="addOtherOptions('discount')">Add Discount</a> |
            <a href="#" @click.prevent="addOtherOptions('other_fees')">Add Other Fees</a></small
          >
          <small v-if="type == 'purchases'"
            ><a href="#" @click.prevent="moreProducts">Add more products</a> |
            <a href="#" @click.prevent="addOtherOptions('shipping')">Add Shipping</a> |
            <a href="#" @click.prevent="addOtherOptions('trx')">Add Cost of Transaction</a> |
            <a href="#" @click.prevent="addOtherOptions('discount')">Add Discount</a> |
            <a href="#" @click.prevent="addOtherOptions('other_cost')">Other cost</a></small
          >
          <small v-if="subtype == 'purchase_return' || subtype == 'sales_return' || /stock/.test(subtype)"
            ><a href="#" @click.prevent="moreProducts">Add more products</a></small
          >
        </div>
        <div class="pv1" v-else>
          <label class="db fade pv1">Description</label>
          <input type="text" class="w-100" v-model="description" />
        </div>
        <div class="pv1">
          <label class="db fade pv1">Entity name</label>
          <v-select @search="fetchEntity" v-model="entity" :taggable="true" style="min-width:150px" :options="tmpOptions">
            <template #no-options="{ search, searching, loading }" class="fade pa1">
              Type entity name...
            </template>
          </v-select>
        </div>
        <div class="pv1">
          <label class="db fade pv1">Reference/Invoice number</label>
          <input type="text" class="w-100" readonly v-model="reference" />
        </div>
        <div class="pv1 flex justify-between items-center w-100">
          <div class="w-50 mr1">
            <label class="db fade pv1">Amount/Value</label>
            <input type="number" class="w-100" v-model="amount" />
          </div>
          <div class="w-50 ml1">
            <label class="db fade pv1">Date</label>
            <input type="date" class="w-100" v-model="date" />
          </div>
        </div>
        <div v-if="type == 'sales' || type == 'purchases'">
          <label class="db fade pv1"><input type="checkbox" v-model="credit" /> This is a credit transaction.</label>
          <div class="pv1 flex justify-between items-center w-100" :style="credit ? '' : 'display:none !important'">
            <div class="w-50 mr1">
              <label class="db fade pv1">Upfront payment</label>
              <input type="number" class="w-100" v-model="initial_deposit" />
            </div>
            <div class="w-50 ml1">
              <label class="db fade pv1">Balance due date</label>
              <input type="date" class="w-100" v-model="due_date" />
            </div>
          </div>
        </div>
        <div class="pt2">
          <button class="w-100" @click.prevent="addTrx" :disabled="addingTrx">
            {{ addingTrx ? "Creating Transaction..." : "Create Transaction" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "https://unpkg.com/vue-select@3.0.0/dist/vue-select.css";
.v-select {
  background: #fff;
}
input[type="search"] {
  padding: 0 !important;
}
.vs--searchable .vs__dropdown-toggle {
  background: #fff;
}
</style>

<script>
const debounce = (func, wait) => {
  let timeout

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

import vSelect from "vue-select"

export default {
  components: {
    vSelect,
  },
  watch: {
    type(n, o) {
      this.products.length = 1
      this.additionalOptions.length = 0
    },
  },
  mounted() {
    //
    this.reference = Date.now()
  },
  data() {
    return {
      productModal: false,
      addingProduct: false,
      addingTrx: false,
      productError: "",

      tmpOptions: [],

      error: "",
      info: "",
      type: "",
      parent_ref: "",
      date: "",
      initial_deposit: 0,
      prepayment: "",
      prepayment_months: 1,
      credit: "",
      due_date: "",
      subtype: "",
      new_product: {
        name: "",
        sku: "",
        description: "",
        size: "",
        color: "",
      },
      products: [
        // {
        //   product: '',
        //   price: '0',
        //   quantity: 1
        // }

        {
          product: "",
          price: "0",
          quantity: 1,
        },
      ],
      additionalOptions: [
        /*{
        label: '',
        value: '',
        fixed: true
      */
      ],
      description: "", // for sales and purchase is product
      entity: {},
      amount: "", // autosummed for sales and purchases
      reference: "", // autogenerated: date and transactions #
    }
  },
  methods: {
    async createProduct() {
      this.addingProduct = true
      try {
        const response = await fetch("/api/products", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(this.new_product),
        })
        const body = await response.json()
        this.addingProduct = false
        this.productModal = false
        if (body.error) {
          this.productError = body.error
          setTimeout(() => {
            this.productError = null
          }, 5000)
          return
        }
        this.tmpOptions = [
          {
            value: body._id,
            label: body.name,
          },
        ]
      } catch (e) {
        this.addingProduct = false
        this.productModal = false
        this.productError = e.message ? e.message : e
        setTimeout(() => {
          this.productError = null
        }, 5000)
      }
    },
    async addTrx() {
      this.addingTrx = true
      const products = []
      for (const p of this.products) {
        if (p.product) {
          products.push(p)
        }
      }
      const data = {
        parent_ref: this.parent_ref,
        type: this.type,
        date: this.date,
        subtype: this.subtype,
        credit: this.credit,
        prepayment: this.prepayment,
        prepayment_months: this.prepayment_months,
        due_date: this.due_date,
        initial_deposit: this.initial_deposit,
        products,
        additionalOptions: this.additionalOptions,
        description: this.description,
        entity: this.entity,
        amount: this.amount,
        reference: this.reference,
      }
      try {
        const response = await fetch("/api/transactions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(data),
        })
        const body = await response.json()
        this.addingTrx = false
        if (body.error) {
          this.error = body.error
          setTimeout(() => {
            this.error = null
          }, 5000)
          return
        }
        // window.location.href = '#/transactions'
        this.info = "Transaction successfully added"
        this.parent_ref = ""
        this.type = ""
        this.date = ""
        this.subtype = ""
        this.credit = ""
        this.prepayment = ""
        this.prepayment_months = 1
        this.due_date = ""
        this.initial_deposit = 0
        this.products = [
          {
            product: "",
            price: "0",
            quantity: 1,
          },
        ]
        this.additionalOptions = []
        this.description = ""
        this.entity = {}
        this.amount = ""
        this.reference = Date.now()
        setTimeout(() => {
          this.info = null
        }, 5000)
      } catch (e) {
        console.log(e)
        this.addingProduct = false
        this.productModal = false
        this.productError = e.message ? e.message : e
        setTimeout(() => {
          this.productError = null
        }, 5000)
      }
    },
    fetchEntity(search, loading) {
      // ...
      if (search.length) {
        loading(true)
        const url = `/api/entities/ac?q=${escape(search)}`
        this.search(loading, url, this)
      }
    },
    fetchProduct(search, loading) {
      // ...
      if (search.length) {
        loading(true)
        const url = `/api/ac/products?q=${escape(search)}`
        this.search(loading, url, this)
      }
    },
    moreProducts() {
      this.products.push({
        product: "",
        price: "0",
        quantity: 1,
      })
    },
    addOtherOptions(key) {
      const options = {
        vat: "VAT",
        commission: "Commission",
        discount: "Discount",
        shipping: "Shipping",
        other_fees: "Other fees",
        trx: "Cost of transaction",
        other_costs: "Other costs",
      }
      this.additionalOptions.push({
        label: options[key],
        key,
        value: "",
        fixed: "Y",
      })
    },
    search: debounce((loading, url, vm) => {
      fetch(url).then(res => {
        res.json().then(json => {
          vm.tmpOptions = json
        })
        loading(false)
      })
    }, 350),
  },
}
</script>
