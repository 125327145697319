import { render, staticRenderFns } from "./SettingsProfile.vue?vue&type=template&id=66ff407b&scoped=true&"
import script from "./SettingsProfile.vue?vue&type=script&lang=js&"
export * from "./SettingsProfile.vue?vue&type=script&lang=js&"
import style0 from "./SettingsProfile.vue?vue&type=style&index=0&id=66ff407b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66ff407b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/dev/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('66ff407b')) {
      api.createRecord('66ff407b', component.options)
    } else {
      api.reload('66ff407b', component.options)
    }
    module.hot.accept("./SettingsProfile.vue?vue&type=template&id=66ff407b&scoped=true&", function () {
      api.rerender('66ff407b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/SettingsProfile.vue"
export default component.exports