<template>
  <div>
    <div>
      <h3 class="asc ma0 pb2">Current</h3>
      <div class="pv2">
        <div style="max-width:400px" class="flex">
          <div class="w-50 tc" style="border-right:1px solid #f5f4fa">
            <h1 v-if="org.billing && org.billing.pro">${{ currentCharge() }}</h1>
            <div v-else><h1 class="dib">$0</h1> (free plan)</div>
            <div class="fade">{{ org.sent }} sent emails</div>
          </div>
          <div class="w-50 pl4 tc">
            <div v-if="org.billing && org.billing.pro">
              <button class="button-del" @click="confirmCancel">Cancel subscription</button>
            </div>
            <div v-else>
              <button class="button-default" @click="upgrade" :disabled="upgrading">{{ upgrading ? 'Upgrading...' : 'Upgrade to Pro' }}</button>
            </div>
            <a href="/pricing" target="_blank">View plans</a>
          </div>
        </div>
      </div>
    </div>
    <div class="info pt3" v-if="info">{{ info }}</div>
    <div class="error pt3" v-if="error">{{ error }}</div>
    <div class="mt4">
      <h3 class="asc ma0 pb2">Cards</h3>
      <div v-for="card in org.cards" :key="card.id" :class="['list-item', deleting.indexOf(card.id) > -1 ? 'deleting':'']">
        <div class="fr pt3 icon">
          <a href="#" v-if="!card.default && !settingDefault" @click.prevent="makeDefault(card.id)"><check-bold-icon title="Make default" data-tooltip="Make default" /></a> <a href="" @click.prevent="confirmDelete(card.id)"><credit-card-remove-icon title="Remove card" data-tooltip="Remove card" /></a>
        </div>
        <div class="bb-line pv3">{{card.type}} <span class="fade">**** {{card.last4}}</span> <small class="tag tag-alt" v-if="card.default">default</small></div>
      </div>
      <div class="bb-line pv3 fade" v-show="cardready"><a href="" @click.prevent="modalOn = true">Add payment card</a></div>
    </div>
    <div class="mt4">
      <h3 class="asc ma0 pb2">Billing history</h3>
      <div class="list-item">
        <div class="bb-line pv3 fade">No billing history</div>
      </div>
    </div>
    <div class="modal" v-show="modalOn" @keyup.esc="closeModal" tabindex="0">
      <div class="center modal-body mt6 pa4" style="width:500px">
        <form method="post" @submit.prevent="addCard">
          <h3 class="ma0 pa0 asc">Add Card</h3>
          <div class="pt2 error" v-if="cardError">{{ cardError }}</div>
          <div ref="card" class="pa3 mv3 card"></div>
          <button type="submit" style="margin-bottom:0" :disabled="addingCard">{{ addingCard ? 'Adding...' : 'Add' }}</button> <button class="button-plain" style="margin-bottom:0" @click.prevent="closeModal" type="button">Cancel</button>
        </form>
      </div>
    </div>
    <div class="modal" v-show="subModalOn" @keyup.esc="closeModal" tabindex="0">
      <div class="center modal-body mt6 pa4" style="width:500px">
        <form method="post" @submit.prevent="cancelSub">
          <h3 class="ma0 pa0 asc">Cancel Subscription</h3>
          <div class="pv3">Kindly confirm you want to cancel your subscription. Note that your account will reset to the free plan and you will be charged <strong>${{ currentCharge() }}</strong> for current usage.</div>
          <button type="submit" style="margin-bottom:0" :disabled="cancelingSub">{{ cancelingSub ? 'Canceling...' : 'Cancel Subscription' }}</button> <button class="button-plain" style="margin-bottom:0" @click.prevent="closeModal" type="button">Cancel</button>
        </form>
      </div>
    </div>
    <div class="modal" v-show="delModalOn" @keyup.esc="closeModal" tabindex="0">
      <div class="center modal-body mt6 pa4" style="width:500px">
        <form method="post" @submit.prevent="deleteCard">
          <h3 class="ma0 pa0 asc">Delete Card</h3>
          <div class="pv3">Kindly confirm you want to delete the card.</div>
          <button type="submit" style="margin-bottom:0" :disabled="deletingCard">{{ deletingCard ? 'Deleting...' : 'Delete Card' }}</button> <button class="button-plain" style="margin-bottom:0" @click.prevent="closeModal" type="button">Cancel</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {loadStripe} from '@stripe/stripe-js'
const style = {
  base: {
    color: '#32325d',
    fontFamily: "'DM Sans', sans-serif'",
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
}
let card

import CreditCardRemoveIcon from 'vue-material-design-icons/CreditCardRemove.vue'
import CheckBoldIcon from 'vue-material-design-icons/CheckBold.vue'

export default {
  data() {
    return {
      deleting: [],
      deletedCard: '',
      info: null,
      error: null,
      stripe: null,
      cardready: false,
      cardError: '',
      addingCard: false,
      upgrading: false,
      settingDefault: false,
      cancelingSub: false,
      deletingCard: false,
      modalOn: false,
      subModalOn: false,
      delModalOn: false,
      range: 10000,
      price: 29,
      emails: 10
    }
  },
  components: {
    CheckBoldIcon, CreditCardRemoveIcon
  },
  computed: {
    org () {
	    return this.$store.state.org
    }
  },
  watch: {
    modalOn(v) {
      this.toggleEsc(v)
    },
    subModalOn(v) {
      this.toggleEsc(v)
    },
    delModalOn(v) {
      this.toggleEsc(v)
    }
  },
  async mounted () {
    this.stripe = await loadStripe('pk_test_51Hp9o4Fj2QY7pQ8Ad4b8lSh8xDkex2eBgg2ynr1CbJiarhuSJnq5pZMmzFYh7icrlQsMIpFeeLYaJEYwoYEdEwi600YzrPzQdt')
    // this.stripe = await loadStripe('pk_live_P3nfGfdoL0z0l3ZG0sS8q48G')
    const elements = this.stripe.elements()
    card = elements.create('card', { style })
    card.mount(this.$refs.card)
    card.on('ready', (event) => {
      this.cardready = true
    })
  },
  methods: {
    toggleEsc(v) {
      if (v) {
        document.addEventListener("keyup", this.onEscapeKeyUp)
      } else {
        document.removeEventListener("keyup", this.onEscapeKeyUp)
      }
    },
    currentCharge () {
      const emails = this.org.sent/1000
      return emails > 10 ? 29 + ((emails - 10) * 2) : 29
    },
    updateRange (e) {
      this.range = e.target.value
      this.emails = e.target.value/1000
      this.price = this.emails > 10 ? 29 + ((this.emails - 10) * 2) : 29
    },
    closeModal () {
      this.modalOn = false
      this.delModalOn = false
      this.subModalOn = false
      card.clear()
    },
    onEscapeKeyUp(event) {
      if (event.which === 27) {
        this.modalOn = false
        this.subModalOn = false
      }
    },
    async addCard() {
      this.addingCard = true
      this.error = ''
      this.cardError = ''
      try {
        const result = await this.stripe.createToken(card)
        // console.log(result)
        if (result.error) {
          this.cardError = result.error.message
        } else {
          const response = await fetch('/api/billing/cards', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: result.token.id })
          })
          let data = {}
          try {
            data = await response.json()
          } catch (j) {}
          if (response.status !== 200) {
            this.cardError = data.error ? data.error : 'There has been a connection error. Try again later.'
          } else {
            this.$store.commit('addCard', data)
            this.modalOn = false
            this.cardError = ''
          }
        }
      } catch (e) {
        this.cardError = e.message
      }
      this.addingCard = false
      card.clear()
    },
    async makeDefault(id) {
      this.error = ''
      this.info = ''
      try {
        this.settingDefault = true
        const response = await fetch(`/api/billing/cards/${id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json;charset=utf-8' },
          body: JSON.stringify({card: id })
        })
        let data = {}
        try {
          data = await response.json()
        } catch (j) {}
        if (response.status !== 200) {
          this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
        } else {
          this.$store.commit('setDefaultCard', id)
        }
      } catch (e) {
        this.error = e.message
      }
      this.settingDefault = false
    },
    async upgrade() {
      try {
        this.upgrading = true
        this.error = ''
        this.cardError = ''
        if (!this.org.cards.length) {
          throw new Error('Add a payment card to upgrade.')
        }
        const response = await fetch(`/api/subscriptions`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json;charset=utf-8' },
          body: JSON.stringify({ updrade: true })
        })
        let data = {}
        try {
          data = await response.json()
        } catch (j) {}
        if (response.status !== 200) {
          this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
        } else {
          let message = 'Account successfully upgraded.'
          if (!this.org.cards.length) {
            message += ' Do not forget to add a payment card as well.'
          }
          this.info = message
          this.$store.commit('upgrade')
        }
      } catch (e) {
        this.error = e.message
      }
      this.upgrading = false
    },
    confirmCancel() {
      this.subModalOn = true
    },
    confirmDelete(card) {
      this.delModalOn = true
      this.deletedCard = card
    },
    async cancelSub() {
      this.error = ''
      this.info = ''
      try {
        this.cancelingSub = true
        const response = await fetch(`/api/subscriptions`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json;charset=utf-8' }
        })
        let data = {}
        try {
          data = await response.json()
        } catch (j) {}
        if (response.status !== 200) {
          this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
        } else {
          this.info = 'Subscription cancelled.'
          this.$store.commit('downgrade')
        }
      } catch (e) {
        this.error = e.message
      }
      this.subModalOn = false
      this.cancelingSub = false
    },
    async deleteCard () {
      this.delModalOn = false
      this.error = ''
      this.info = ''
      this.deleting.push(this.deletedCard)
      const response = await fetch(`/api/billing/cards/${this.deletedCard}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      this.deleting.splice(this.deleting.indexOf(this.deletedCard), 1)
      if (response.status !== 200) {
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
      } else {
        this.info = 'Card removed'
        this.$store.commit('removeCard', this.deletedCard, data.default_card)
      }
      this.deletedCard = ''
    }
  }
}
</script>

<style scoped>
.card {
  border: 1px solid #ddd;
  border-radius: 3px;
}
svg {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  position: relative;
  top: 2px
}
.icon {
  display: none;
}
.deleting {
  opacity: 0.4;
}
.list-item:hover .icon {
  display: block
}
.list-item.deleting:hover .icon {
  display: none
}
.icon a svg {
  color: #777;
}
.icon a:hover svg {
  color: #444;
}
</style>