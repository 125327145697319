var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("div", { staticClass: "fixed w-20-l w-10 h-100 side" }, [
        _c("header", { staticClass: "pv3 ph4 bb-line relative" }, [
          _c(
            "h3",
            { staticClass: "pa0 ma0 truncate" },
            [
              _vm.org.accounts && _vm.org.accounts.length
                ? _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.toggleSwitch.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("chevron-down-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.switchOn,
                            expression: "!switchOn",
                          },
                        ],
                        staticClass: "mr2",
                      }),
                      _c("chevron-up-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.switchOn,
                            expression: "switchOn",
                          },
                        ],
                        staticClass: "mr2",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "hdr-color" }, [
                        _vm._v(_vm._s(_vm.org.name)),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "router-link",
                    { staticClass: "hdr-color", attrs: { to: "/" } },
                    [_vm._v(_vm._s(_vm.org.name) + " ")]
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.switchOn,
                  expression: "switchOn",
                },
              ],
              staticClass: "pt1",
            },
            _vm._l(_vm.org.accounts, function (account) {
              return _c(
                "div",
                { key: account.id, staticClass: "f5 pv1 ma0" },
                [
                  _c("chevron-up-icon", {
                    staticClass: "mr2",
                    staticStyle: { visibility: "hidden" },
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.switchAcc(account.id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(account.name))]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pv4-ns pr4 ph4-ns ph3-m ph3 mb4" }, [
          _c(
            "div",
            { staticClass: "mb3 dib-l dn" },
            [
              _c(
                "router-link",
                {
                  staticClass: "b button button-default",
                  staticStyle: {
                    display: "inline-block",
                    padding: "10px 20px",
                  },
                  attrs: { to: "/transactions/new" },
                },
                [_vm._v("Add a new transaction")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "nav" }, [
            _c(
              "li",
              { staticClass: "pt1 pb2 ph2" },
              [
                _c(
                  "router-link",
                  {
                    class: _vm.$route.path === "/" ? "active" : "",
                    attrs: { to: "/" },
                  },
                  [
                    _c("home-icon", { staticClass: "mr2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "di-l dn" }, [
                      _vm._v("Dashboard"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "pt1 pb2 ph2" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/transactions", "active-class": "active" } },
                  [
                    _c("zap-icon", { staticClass: "mr2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "di-l dn" }, [
                      _vm._v("Transactions"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "pt1 pb2 ph2" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/pnr", "active-class": "active" } },
                  [
                    _c("send-icon", { staticClass: "mr2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "di-l dn" }, [
                      _vm._v("Payables/Receiveables"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "pt1 pb2 ph2" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/entities", "active-class": "active" } },
                  [
                    _c("user-plus-icon", { staticClass: "mr2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "di-l dn" }, [
                      _vm._v("Entities"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "pt1 pb2 ph2" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/inventory", "active-class": "active" } },
                  [
                    _c("user-plus-icon", { staticClass: "mr2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "di-l dn" }, [
                      _vm._v("Inventory"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "pt1 pb2 ph2" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/pricing", "active-class": "active" } },
                  [
                    _c("user-plus-icon", { staticClass: "mr2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "di-l dn" }, [_vm._v("Pricing")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "pt1 pb2 ph2" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/invoicing", "active-class": "active" } },
                  [
                    _c("user-plus-icon", { staticClass: "mr2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "di-l dn" }, [
                      _vm._v("Invoicing"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "pt1 pb2 ph2" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/invoicing", "active-class": "active" } },
                  [
                    _c("user-plus-icon", { staticClass: "mr2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "di-l dn" }, [
                      _vm._v("Insights"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "pt1 pb2 ph2" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/reports", "active-class": "active" } },
                  [
                    _c("user-plus-icon", { staticClass: "mr2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "di-l dn" }, [_vm._v("Reports")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt3" }),
          _vm._v(" "),
          _c("ul", { staticClass: "nav" }, [
            _c(
              "li",
              { staticClass: "pt2 pb2 ph2" },
              [
                _c(
                  "router-link",
                  {
                    class: _vm.$route.path.startsWith("/settings")
                      ? "active"
                      : "",
                    attrs: { to: "/settings/profile" },
                  },
                  [
                    _c("settings-icon", { staticClass: "mr2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "di-l dn" }, [
                      _vm._v("Settings"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "pt2 pb2 ph2" }, [
              _c(
                "a",
                { attrs: { href: "/logout" } },
                [
                  _c("log-out-icon", { staticClass: "mr2" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "di-l dn" }, [_vm._v("Logout")]),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("router-view", { attrs: { org: _vm.org } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }