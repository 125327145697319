<template>
  <div>
    <div class="mid fixed w-90 w-80-l h-100" style="border-right:0;background-color:#f5f6fa">
      <div class="w-90 center pv5">
        <div class="error pb3" v-if="error">{{ error }}</div>
        <div class="pa2 fr"><span v-show="showmonth">This month</span><a href="#" class="fade" v-show="!showmonth" @click.prevent="showmonth = true">This Month</a> <span v-show="!showmonth" class="ml2 dib">All time</span><a href="#" class="ml2 fade" v-show="showmonth" @click.prevent="showmonth = false">All time</a></div>
        <div class="pa2 b">&nbsp;</div>
        <div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : 0 }}</h2>
              <div>Total Qty Purchase</div>
            </div>
          </div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : 0 }}</h2>
              <div>Total Cost</div>
            </div>
          </div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : 0 }}</h2>
              <div>Total Qty Sold</div>
            </div>
          </div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : 0 }}</h2>
              <div>Total Sales</div>
            </div>
          </div>
          <div class="cb"></div>
        </div>
        <div>
          <div class="fl w-third tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : 0 }}</h2>
              <div>Total Stock on Hand</div>
            </div>
          </div>
          <div class="fl w-third tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : 0 }}</h2>
              <div>Total Value of Stock on Hand</div>
            </div>
          </div>
          <div class="fl w-third tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : 0 }}</h2>
              <div>Average Inventory Day</div>
            </div>
          </div>
          <div class="cb"></div>
        </div>
        
        <table class="mt4">
          <thead>
            <tr class="tc">
              <th></th>
              <th colspan="3">Purchase</th>
              <th colspan="3">Sales</th>
              <th colspan="2">Stock on Hand</th>
              <th colspan="2">Profitability</th>
            </tr>
            <tr>
              <th>Product name</th>
              <th>Total Qty</th>
              <th>Total Purchase Cost</th>
              <th>Average Cost</th>
              <th>Total Qty</th>
              <th>Total Sales Price</th>
              <th>Average Price</th>
              <th>Current Stock on Hand</th>
              <th>Value of Stock on Hand</th>
              <th>Average Profit</th>
              <th>Profitability rate</th>
            </tr>
          </thead>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: '',
      loading: true,
      showmonth: true,
      data: {
        mails: 0,
        opens: 0,
        clicks: 0,
        fails: 0,
        mails_tm: 0,
        opens_tm: 0,
        clicks_tm: 0,
        fails_tm: 0,
        events: 0,
        automations: 0,
        customers: 0,
        team_active: 0,
        team_all: 0,
        recent_emails: [],
        recent_customers: []
      }
    }
  }
}
</script>

<style scoped>
</style>