import { render, staticRenderFns } from "./Entity.vue?vue&type=template&id=4c58177e&scoped=true&"
import script from "./Entity.vue?vue&type=script&lang=js&"
export * from "./Entity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c58177e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/dev/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4c58177e')) {
      api.createRecord('4c58177e', component.options)
    } else {
      api.reload('4c58177e', component.options)
    }
    module.hot.accept("./Entity.vue?vue&type=template&id=4c58177e&scoped=true&", function () {
      api.rerender('4c58177e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/Entity.vue"
export default component.exports