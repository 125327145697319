var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _vm.isAdmin
        ? _c("h3", { staticClass: "asc ma0 pb2" }, [_vm._v("Invite members")])
        : _vm._e(),
      _vm._v(" "),
      _vm.isAdmin
        ? _c(
            "form",
            {
              attrs: { method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.sendInvite.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "pv1" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.invite,
                      expression: "invite",
                    },
                  ],
                  attrs: { type: "email", placeholder: "Email", required: "" },
                  domProps: { value: _vm.invite },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.invite = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  { attrs: { type: "submit", disabled: _vm.working } },
                  [_vm._v(_vm._s(_vm.working ? "Adding..." : "Invite"))]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.info
      ? _c("div", { staticClass: "info" }, [_vm._v(_vm._s(_vm.info))])
      : _vm._e(),
    _vm._v(" "),
    _vm.error
      ? _c("div", { staticClass: "pv2 error" }, [_vm._v(_vm._s(_vm.error))])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt4" },
      [
        _c("h3", { staticClass: "asc ma0 pb2" }, [_vm._v("Members")]),
        _vm._v(" "),
        _vm._l(_vm.org.members, function (member) {
          return _c(
            "div",
            {
              key: member.email,
              class: [
                "list-item",
                _vm.deleting.indexOf(member.email) > -1 ? "deleting" : "",
              ],
            },
            [
              member.email != _vm.org.user.email && _vm.isAdmin
                ? _c("div", { staticClass: "fr pt3 icon" }, [
                    member.pending
                      ? _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.sendInvite(member.email)
                              },
                            },
                          },
                          [
                            _c("email-send-icon", {
                              attrs: {
                                title: "Resend Invitation",
                                "data-tooltip": "Resend Invitation",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.changeRole(member.email, member.role)
                          },
                        },
                      },
                      [
                        _c("key-outline-icon", {
                          attrs: {
                            title: "Change role",
                            "data-tooltip": "Change role",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.confirmDelete(member.email)
                          },
                        },
                      },
                      [
                        _c("account-remove-icon", {
                          attrs: {
                            title: "Remove user",
                            "data-tooltip": "Remove user",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "bb-line pv3" }, [
                _vm._v(_vm._s(member.name)),
                _c("span", { staticClass: "fade" }, [
                  _vm._v(
                    _vm._s(
                      member.name ? " (" + member.email + ")" : member.email
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "small",
                  {
                    class: [
                      "tag",
                      member.role === "admin" ? "tag-alt" : "tag-alt-b",
                    ],
                  },
                  [_vm._v(_vm._s(member.role))]
                ),
                _vm._v(" "),
                member.pending
                  ? _c("small", { staticClass: "tag" }, [_vm._v("pending")])
                  : _vm._e(),
              ]),
            ]
          )
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.modalOn,
            expression: "modalOn",
          },
        ],
        staticClass: "modal",
        attrs: { tabindex: "0" },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            return _vm.closeModal.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "w-40-ns w-50 center modal-body mt6 pa4" }, [
          _c(
            "form",
            {
              attrs: { method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.deleteMember.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "mb3" }, [
                _vm._v(
                  "Kindly confirm you want to remove the user by entering the email "
                ),
                _c("strong", [_vm._v(_vm._s(_vm.deleteEmail))]),
                _vm._v(" in the text field below."),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pv2" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.deleteValue,
                      expression: "deleteValue",
                    },
                  ],
                  attrs: {
                    type: "email",
                    placeholder: _vm.deleteEmail,
                    required: "",
                  },
                  domProps: { value: _vm.deleteValue },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.deleteValue = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { type: "submit" },
                },
                [_vm._v("Remove")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button-plain",
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.closeModal.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }