var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "mid fixed w-90 w-80-l h-100",
        staticStyle: { "border-right": "0", "background-color": "#f5f6fa" },
      },
      [
        _c("div", { staticClass: "w-70 center pv5" }, [
          _vm.error
            ? _c("div", { staticClass: "error pb3" }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "pa2 fr" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showmonth,
                    expression: "showmonth",
                  },
                ],
              },
              [_vm._v("This month")]
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showmonth,
                    expression: "!showmonth",
                  },
                ],
                staticClass: "fade",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.showmonth = true
                  },
                },
              },
              [_vm._v("This Month")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showmonth,
                    expression: "!showmonth",
                  },
                ],
                staticClass: "ml2 dib",
              },
              [_vm._v("All time")]
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showmonth,
                    expression: "showmonth",
                  },
                ],
                staticClass: "ml2 fade",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.showmonth = false
                  },
                },
              },
              [_vm._v("All time")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pa2 b" }, [_vm._v(" ")]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "fl w-25 tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.loading
                        ? 0
                        : _vm.showmonth
                        ? _vm.data.mails_tm
                        : _vm.data.mails
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Transactions")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl w-25 tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.loading
                        ? "..."
                        : _vm.showmonth
                        ? _vm.data.opens_tm
                        : _vm.data.opens
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Debtors")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl w-25 tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.loading
                        ? "..."
                        : _vm.showmonth
                        ? _vm.data.clicks_tm
                        : _vm.data.clicks
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Creditors")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl w-25 tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.loading
                        ? "..."
                        : _vm.showmonth
                        ? _vm.data.fails_tm
                        : _vm.data.fails
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Fails")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cb" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt3" }, [
            _c("div", { staticClass: "pa2 b" }, [_vm._v("Account")]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "fl w-25 tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : _vm.data.automations)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("-")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl w-25 tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : _vm.data.customers)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Customers")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl w-25 tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : _vm.data.team_active)),
                  _c("span", { staticClass: "fadest" }, [
                    _vm._v(
                      "/" + _vm._s(_vm.loading ? "..." : _vm.data.team_all)
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "truncate" }, [
                  _vm._v("Team members"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cb" }),
          ]),
          _vm._v(" "),
          _vm._m(1),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fl w-25 tc pa2" }, [
      _c("div", { staticClass: "box ph3 pt3 pb4" }, [
        _c("h2", { staticClass: "mb2" }, [_vm._v("-")]),
        _vm._v(" "),
        _c("div", { staticClass: "truncate" }, [_vm._v("-")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt3" }, [
      _c("div", { staticClass: "fl w-50 pa2" }, [
        _c("div", { staticClass: "box pa3" }, [
          _c("h4", { staticClass: "mt0 mb2" }, [_vm._v("-")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fl w-50 pa2" }, [
        _c("div", { staticClass: "box pa3" }, [
          _c("h4", { staticClass: "mt0 mb2" }, [_vm._v("-")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cb" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }